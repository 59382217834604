const TeacherTrainingAndSupport = {
    InitiativeMainTextTitle: "Nurturing tomorrow's leaders in the classroom",
    InitiativeMainTextText: "Quality education begins with dedicated and skilled educators. BackToSchool's Teacher Training and Support Campaign is committed to enhancing the capabilities of teachers in underserved communities. We believe that by empowering teachers, we can create a ripple effect that improves the quality of education for all children.",
    InitiativeSectionOneImg: `${process.env.PUBLIC_URL + '/images/african-descent-teacher-in-kindergarten-school_BacktoschoolNGO.webp'}`,
    InitiativeSectionOneImgAlt: "Back to school NGO Teacher training and support initiative",
    InitiativeSectionOneImgWidth: "558px",
    InitiativeSectionOneImgHeight: "371px",
    InitiativeSectionOneTitle: "Why Support Teachers?",
    InitiativeSectionOneTextBody: "Quality education stems from passionate, skilled educators. Our campaign is a testament to the power teachers hold in reshaping education landscapes.",
    InitiativeSectionTwoTitle: "How We Empower Educators",
    InitiativeSectionTwoBTNText: "Get Involved",
    InitiativeSectionTwoBoxTitleOne: "Train",
    InitiativeSectionTwoBoxTextOne: "We enhance teaching methodologies through specialized programs.",
    InitiativeSectionTwoBoxTitleTwo: "Equip",
    InitiativeSectionTwoBoxTextTwo: "From lesson aids to collaborative platforms, we ensure teachers have the best.",
    InitiativeSectionTwoBoxTitleThree: "Community",
    InitiativeSectionTwoBoxTextThree: "Building bridges, fostering collaboration, and promoting knowledge exchange among educators.",
    InitiativeWhatWeDoSectionText: "Our campaign provides comprehensive training, resources, and support to educators. By doing so, we:",
    InitiativeWhatWeDoSectionListOne: "Elevate the quality of education in schools.",
    InitiativeWhatWeDoSectionListTwo: "Empower teachers with the skills and knowledge they need to excel in their roles.",
    InitiativeWhatWeDoSectionListThree: "Foster a collaborative and supportive community of educators.",
    InitiativeWhatWeDoSectionImg: `${process.env.PUBLIC_URL + '/images/Teacher Training_BacktoschoolNGO.webp'}`,
    InitiativeWhatWeDoSectionImgAlt: "Backtoschool NGO Teacher training and support initiative",
    InitiativeWhatWeDoSectionImgWidth: "506px",
    InitiativeWhatWeDoSectionImgHeight: "414px",
};

export default TeacherTrainingAndSupport;