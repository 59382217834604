const SponsorChild = {
    InitiativeMainTextTitle: "Transforming lives, one child at a time",
    InitiativeMainTextText: "Our Sponsor a Child Campaign is a powerful way to make a lasting impact on a child's education. By becoming a sponsor, you have the opportunity to change the trajectory of a child's life, providing them with access to quality education and a brighter future.",
    InitiativeSectionOneImg: `${process.env.PUBLIC_URL + '/images/two-african-children-with-their-heavy-backpacks.webp'}`,
    InitiativeSectionOneImgAlt: "Back to school NGO Sponsor a Child Campaign",
    InitiativeSectionOneImgWidth: "556px",
    InitiativeSectionOneImgHeight: "374px",
    InitiativeSectionOneTitle: "Why Sponsorship?",
    InitiativeSectionOneTextBody: "By sponsoring a child's education, you're igniting change – not just for one child, but for entire communities. This campaign aims to reshape futures by breaking the chains of poverty.",
    InitiativeSectionTwoTitle: "How we change lives",
    InitiativeSectionTwoBTNText: "Sponsor a child",
    InitiativeSectionTwoBoxTitleOne: "Choose",
    InitiativeSectionTwoBoxTextOne: "Pick a child to sponsor and support their education journey.",
    InitiativeSectionTwoBoxTitleTwo: "Fund",
    InitiativeSectionTwoBoxTextTwo: "Your contributions cover everything – from school fees to educational resources.",
    InitiativeSectionTwoBoxTitleThree: "Connect",
    InitiativeSectionTwoBoxTextThree: "Stay updated and watch as your sponsored child thrives academically.",
    InitiativeWhatWeDoSectionText: "Through the Sponsor a Child Campaign, you can directly support a child's education for a school year or longer. Your sponsorship covers school fees, supplies, and more. With your help, we:",
    InitiativeWhatWeDoSectionListOne: "Ensure that children from economically disadvantaged backgrounds have equal opportunities.",
    InitiativeWhatWeDoSectionListTwo: "Break the cycle of poverty and open doors to a world of possibilities.",
    InitiativeWhatWeDoSectionListThree: "Create a strong bond between sponsors and beneficiaries, fostering mutual growth.",
    InitiativeWhatWeDoSectionImg: `${process.env.PUBLIC_URL + '/images/BacktoschoolNGO Sponsor a Child Campaign.webp'}`,
    InitiativeWhatWeDoSectionImgAlt: "Sponsor a Child Campaign",
    InitiativeWhatWeDoSectionImgWidth: "506px",
    InitiativeWhatWeDoSectionImgHeight: "414px",
};

export default SponsorChild;