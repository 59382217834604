const SkillCampaign = {
    InitiativeMainTextTitle: "Empowering Youth for a Brighter Tomorrow",
    InitiativeMainTextText: "Education doesn't end with the classroom. Our Skill Acquisition Campaign focuses on equipping out-of-school youth with practical skills that enhance their employability and open doors to entrepreneurship.",
    InitiativeSectionOneImg: `${process.env.PUBLIC_URL + '/images/medium-shot-people-working-with-tools.webp'}`,
    InitiativeSectionOneImgAlt: "Skill Acquisition Campaign",
    InitiativeSectionOneImgWidth: "556px",
    InitiativeSectionOneImgHeight: "373px",
    InitiativeSectionOneTitle: "Why This Campaign?",
    InitiativeSectionOneTextBody: "Beyond classrooms, skills drive futures. We focus on arming the youth with pragmatic skills, opening doors to employment and entrepreneurial avenues.",
    InitiativeSectionTwoTitle: "How We Unlock Potential",
    InitiativeSectionTwoBTNText: "Support Youth",
    InitiativeSectionTwoBoxTitleOne: "Educate",
    InitiativeSectionTwoBoxTextOne: "From digital literacy to vocational training, we cover the spectrum.",
    InitiativeSectionTwoBoxTitleTwo: "Enable",
    InitiativeSectionTwoBoxTextTwo: "Equip the youth to either secure jobs or spearhead their own ventures.",
    InitiativeSectionTwoBoxTitleThree: "Economic Growth",
    InitiativeSectionTwoBoxTextThree: "By fostering skilled individuals, we sow seeds for sustainable economic progress.",
    InitiativeWhatWeDoSectionText: "Through this campaign, we offer training in vital skills, including digital literacy, vocational skills, and entrepreneurship. By doing so, we:",
    InitiativeWhatWeDoSectionListOne: "Bridge the skills gap among youth, increasing their potential for meaningful employment.",
    InitiativeWhatWeDoSectionListTwo: "Create opportunities for entrepreneurship and self-sufficiency.",
    InitiativeWhatWeDoSectionListThree: "Contribute to poverty reduction and sustainable economic growth.",
    InitiativeWhatWeDoSectionImg: `${process.env.PUBLIC_URL + '/images/Skill Acquisition Campaign.webp'}`,
    InitiativeWhatWeDoSectionImgAlt: "Back to school-Skill Acquisition Campaign",
    InitiativeWhatWeDoSectionImgWidth: "506px",
    InitiativeWhatWeDoSectionImgHeight: "414px",
};

export default SkillCampaign;