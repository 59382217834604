const About = {
    InitiativeMainTextTitle: "Who We Are",
    InitiativeMainTextText: "BackToSchool is a non-profit organization dedicated to creating a brighter future through education. Our journey began with a simple belief that education is the most powerful tool to transform lives. We believe that every child, regardless of their background or circumstances, deserves access to quality education.",
    // InitiativeSectionOneImg: `${process.env.PUBLIC_URL + '/images/back to school supply drive.webp'}`,
    // InitiativeSectionOneImgAlt: "Back to school NGO supply drive",
    // InitiativeSectionOneImgWidth: "558px",
    // InitiativeSectionOneImgHeight: "371px",
    // InitiativeSectionOneTitle: "Why This Drive?",
    // InitiativeSectionOneTextBody: "Back To School stands on the principle that every child's education journey shouldn't be hindered by a lack of resources. Through our Supply Drive, we aim to even out the educational playing field for all.",
    InitiativeSectionTwoTitle: "Our Vision",
    InitiativeSectionTwoBTNText: "",
    InitiativeSectionTwoBoxTitleOne: "Equality in Education",
    InitiativeSectionTwoBoxTextOne: "We envision a world where education is not a privilege but a fundamental right for every child. We are committed to breaking down barriers that hinder access to quality education",
    InitiativeSectionTwoBoxTitleTwo: "Empowering Dreams",
    InitiativeSectionTwoBoxTextTwo: "Our vision is to empower children to dream, aspire, and achieve. We believe that education is the key to unlocking their limitless potential.",
    InitiativeSectionTwoBoxTitleThree: "Community-Centered",
    InitiativeSectionTwoBoxTextThree: "We see education as a collective effort. We work closely with communities, parents, teachers, and volunteers to create a supportive ecosystem for learning.",
    WhatWeDoSectionSubTitle: "What we do",
    WhatWeDoSectionTitle: "Our primary causes",
    WhatWeDoSectionText: "Through our programs and initiatives, we have been able to make a significant impact on the lives of individuals and communities in need. We have provided educational resources and opportunities, promoted access to healthcare, alleviated hunger and poverty, and promoted sustainable practices to protect our planet. This funding has come from a variety of sources, including individual donations, corporate sponsorships, and foundation grants.",
    WhatWeDoSectionBoxOneText: "Back to School persistently removes financial and other barriers to education for youngsters. We provide free, high-quality education to underprivileged children and equip them with life skills.",
    WhatWeDoSectionBoxTwoText: "We do this by providing education, healthcare, and relieving poverty and hunger. To reach more people, we partner with local schools, businesses, and other groups. Our programs improve children's and communities' futures.",
};

export default About;